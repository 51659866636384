@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif !important;
}

/* Navbar CSS */
.navbar {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  border-bottom: solid;
  /* border-color: #d9d9d9; */
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
}

.divPage{
  padding-left: 10vh;
  padding-right: 10vh;
  padding-top: 2vh;
}

.home {
  align-items: center;
  justify-content: center;
  display: flex;
  /* margin-top: 60px; */
  color: #111111;
}

.priceupdate, .profile{
  margin-top: 7vw;
  align-items: center;
  justify-content: center;
  /* position: relative; */
  display: flex;
}

.logo {
  margin-left: 2rem;
  font-size: 1.5rem;
  background: none;
  color: #7F7F7F;
}

.navbar{
  color: white;
}

.headerIcons, .headerIcons:hover{
  font-size: 1.2rem;
  margin-right: 2rem;
  background: none;
  color: #343434;
  align-items: center;
}

.headerName, .headerName:hover{
  font-size: 1.2rem;
  margin-right: 2rem;
  background: none;
  color: #343434;
  align-items: center;
  text-decoration: none;
  cursor: default;
}


.nav-menu.active {
  left: 0;
  transition: 450ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1e354e;
  color: #fff;
}

.nav-menu-items {
  width: 100%;
  padding: 0;
}

.navbar-toggle {
  background-color: #7F7F7F;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}


/* Páginas Inicias */
.bodyLogin {
  height: 97vh;
  width: 100vw;
  margin: 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #fff;
}
h4 {
  font-size: 24px;
  font-weight: 600;
  color: #000;
  opacity: 0.85;
}
label {
  font-size: 12.5px;
  color: #000;
  opacity: 0.8;
  font-weight: 400;
}
.log-in {
  justify-content: center;
  display: flex;
  width: 30%;
}

form {
  padding: 40px 40px;
  background: #fefefe;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
}
form h4 {
  margin-bottom: 20px;
  color: rgba(0, 0, 0, .5);
}
form h4 span {
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
}
form p {
  line-height: 155%;
  margin-bottom: 5px;
  font-size: 14px;
  color: #000;
  opacity: 0.65;
  font-weight: 400;
  max-width: 300px;
  margin-bottom: 20px;
  margin-top: 20px;
}
a.discrete {
  color: rgba(0, 0, 0, .4);
  font-size: 14px;
  border-bottom: solid 1px rgba(0, 0, 0, .0);
  padding-bottom: 4px;
  margin-left: auto;
  font-weight: 300;
  transition: all 0.3s ease;
  margin-top: 40px;
}
a.discrete:hover {
  border-bottom: solid 1px rgba(0, 0, 0, .2);
}
.button {
  cursor: pointer;
  -webkit-appearance: none;
  width: auto;
  min-width: 100px;
  border-radius: 24px;
  text-align: center;
  padding: 15px 40px;
  margin-top: 5px;
  background-color: rgba(0, 0, 0, .5);
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, .13);
  border: none;
  transition: all 0.3s ease;
  outline: 0;
}
button:hover {
  transform: translateY(-3px);
  box-shadow: 0 2px 6px -1px rgba(182, 157, 230, .65);
}
button:hover:active {
  transform: scale(0.99);
}
input {
  font-size: 16px;
  padding: 20px 0px;
  height: 56px;
  border: none;
  border-bottom: solid 1px rgba(0, 0, 0, .1);
  background: #fff;
  min-width: 280px;
  box-sizing: border-box;
  transition: all 0.3s linear;
  color: #000;
  font-weight: 400;
  -webkit-appearance: none;
}
input:focus {
  border-bottom: solid 1px #b69de6;
  outline: 0;
  box-shadow: 0 2px 6px -8px rgba(182, 157, 230, .45);
}
.floating-label {
  position: relative;
  margin-bottom: 10px;
}
.floating-label label {
  position: absolute;
  top: calc(50% - 7px);
  left: 0;
  opacity: 0;
  transition: all 0.3s ease;
}
.floating-label input:not(:placeholder-shown) {
  padding: 28px 0px 12px 0px;
}
.floating-label input:not(:placeholder-shown) + label {
  transform: translateY(-10px);
  opacity: 0.7;
}
.session {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  margin: auto auto;
  /* background: #fff; */
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}
.left {
  width: 70%;
  height: 100vh;
  position: relative;
  background-image: url("https://images.pexels.com/photos/7947657/pexels-photo-7947657.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"); 
  background-position: center;
  background-size: cover;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}
.left svg {
  height: 40px;
  width: auto;
  margin: 20px;
}

.recoverPassword{
  margin-bottom: 5px;
  font-size: 11px;
  color: gray;
  cursor: pointer;
  text-decoration: none;
}

.signin{
  margin-bottom: 20px;
  font-size: 11px;
  color: gray;
  cursor: pointer;
  text-decoration: none;
}

h5 {
  font-size: 18px;
  font-weight: 600;
  color: rgb(34, 30, 30);
  opacity: 0.85;
}

form h5 {
  margin-bottom: 20px;
  color: rgba(0, 0, 0, .5);
  
}
form h5 span {
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
}

/* Cards Home */
.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
}

.card {
  height: 300px;
  width: 300px;
  border-radius: 25px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-color: transparent;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  cursor: pointer;
}

.cards{
  color: white;
  font-weight: 600;
  font-size: 22px;
  margin-top: 20px;
}

.card0{
background-color: #f57c00;
}

.card1{
  background-color: #8bc34a;
  
}
.card2{
  background-color: #fbc02d;
}

.card31{
  background-color: #36b6ff;
}

.homeIcon{
  color: white;
  font-size: 40px;
  margin-bottom: 20px;
}

/* Cards Atualização Preços */
.container2 {
  height: 55vh;
  /* width: 50vw; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 200px;
  margin-right: 200px;
  align-items: center; 
   display: flex; 
}

.container3{
  height: 55vh;
  /* width: 50vw; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 150px;
  margin-right: 150px;
  align-items: center; 
   display: flex; 
}

.card4{
  background-color: #96BF49;
  
}
.card5{
  background-color: #1b769c;
}

.card6{
  background-color: #29a851;
}

.card7{
  background-color: #c5a7e5;
}

.card8{
  background-color: #ffc107;
}

.card_ {
  height: 300px;
  width: 300px;
  border-radius: 25px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-color: transparent;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  cursor: pointer;
}

.card_d {
  height: 300px;
  width: 300px;
  border-radius: 25px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-color: transparent;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  cursor: pointer;
  padding: 25px;
  text-align: center;
  color: #000;
}


.cards_{
  color: white;
  font-weight: 600;
  font-size: 18px;
  margin-top: 20px;
}

/* Price Update */
.css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator{
  box-shadow: none;
}

.card3{
  height: 100px;
  width: 100px;
  border-radius: 25px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-color: transparent;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  cursor: pointer;
}

.priceupdateIcon{
  color: white;
  font-size: 70px;
}

.csv{
  color: white;
  font-size: 50px;
}

.buttonVerificacao{
  text-align: start;
}

.resumo{
  color: #111111;
  margin-right: 5px;
}

.csvText{
  color: #111111;
  font-size: 13px;
  text-align: center;
}


.footer {
  background-color: #F0F2F5;
  height: 30px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerText, .footerText:hover{
  font-size: 15px;
  color: #111111;
  text-decoration: none;
}

.tituloCardsD{
  font-size: 26px;
  color: #000;
}

.tituloCardsD2{
  font-size: 26px;
  color: #000;
}

.divMapeamento{
  height: 300px;
  width: 300px;
  border: 0.2px solid grey;
  overflow-y: scroll;
  margin-top: 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  text-align: center;
  border-top: none;
}

.divMapeamento2{
  height: 320px;
  width: 300px;
  border: 0.2px solid grey;
  overflow-y: scroll;
  margin-top: 55px;
  text-align: center;
  border-radius: 5px;
}

.mapeamento-texto {
  display: inline-block;
  margin-right: 10px;
}

.mapeamento-botao {
  display: inline-block;
  margin-left: 10px;
}

.inputlogin{
  width: 75%;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 1000px) {
  .log-in {
    width: 100%;
  }
  .left {
    width: 0%;
  }
  .bodyLogin{
    justify-content: center;
  }
  .inputlogin{
    width: 40%;
  }
  form p {
    width: 40%;
    max-width: max-content;
  
  }
}

@media (max-width: 500px) {
  .inputlogin{
    width: 80%;
  }
  form p {
    width: 70%;
    max-width: max-content;
  
  }
}